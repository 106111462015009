<template lang="pug">
.brand-rule-box(:class="ruleBoxClasses")
  frontend-rules-color-box.flex-shrink-0.mr-3(:type="ruleSVG")
  .flex-grow-1.overflow-hidden
    .d-flex.align-items-center
      .brand-rule-box-title {{ title }}
      .brand-badge.brand-badge-secondary.ml-3(
        v-if="isRecommendedRule(frontendRule.type) && !small"
      ) {{ $t('recommended') }}
    frontend-rule-lead.brand-rule-box-lead(
      v-if="frontendRule.options"
      :rule="frontendRule"
      :domain="campaign.domain"
    )
    .brand-rule-box-lead(v-else) {{ $t(`frontendRules.${realType}.lead`, { brand: brandName }) }}
  slot(name="action")
</template>

<script>
  import FrontendRulesColorBox from '@/components/FrontendRulesColorBox.vue';
  import FrontendRuleLead from '@/components/FrontendRuleLead.vue';
  import { isRecommendedRule } from '@/config/recommendedSettings';
  import { mapGetters, createNamespacedHelpers } from 'vuex';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    components: {
      FrontendRulesColorBox,
      FrontendRuleLead,
    },

    props: {
      frontendRule: {
        type: Object,
      },
      campaign: {
        type: Object,
      },
      small: {
        type: Boolean,
        default: false,
      },
      isNew: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters(['brandName', 'hasAccountFeature']),
      ...paymentGetters(['isPageViewBasedPackage']),
      ruleBoxClasses() {
        return {
          'brand-rule-box-small': !!this.small,
          [`${this.frontendRule?.type}`]: this.frontendRule,
        };
      },
      title() {
        let transKey = this.frontendRule.type;

        if (transKey === 'campaignProgressState') {
          transKey += '.turbo';
        } else if (transKey === 'visitorCartRevamp') {
          transKey = 'visitorCart';
        }

        return this.$t(`frontendRules.${transKey}.title`, { brand: this.brandName });
      },
      ruleSVG() {
        return this.frontendRule.type;
      },
      realType() {
        if (this.frontendRule.type === 'visitorCartRevamp') {
          return this.frontendRule.type.replace('Revamp', '');
        }

        return this.frontendRule.type;
      },
    },

    methods: {
      isRecommendedRule,
    },
  };
</script>
