<template lang="pug">
div
  transition(name="fade")
    .template-filter-overlay(v-if="opened" @click="$emit('update:opened', false)")
  .template-filter(:class="opened ? 'template-filter-opened' : ''")
    .template-filter-title-holder(v-if="isMobile")
      .template-filter-title {{ $t('filterBy') }}
      close-icon.ml-auto(
        :width="13"
        :height="13"
        color="#AAB1C1"
        @click.native="$emit('update:opened', false)"
      )
    template(v-if="!newChooser && templateCategories && templateCategories.length")
      om-slide-down(:title="$t('newCampaignFlow.categories')" :slideActive="false")
        .template-filter-item(
          v-if="i < 3"
          v-for="(category, i) in templateCategories"
          :id="`template-categories-${category}`"
        )
          .d-flex-1.justify-content-start.align-items-center
            input(
              :id="category"
              type="checkbox"
              :value="category"
              v-model="selectedCategoriesComputed"
              :data-track="'filter by category ' + category"
            )
            label.template-filter-item-label.template-filter-item-label-active(:for="category") {{ $t(`templateFilter.categories.${category}`) }}
        template(v-if="templateCategories && templateCategories.length > 3")
          slide-up-down(:active="categoryShowMore" :duration="400")
            .template-filter-item(
              v-if="i >= 3"
              v-for="(category, i) in templateCategories"
              :id="`template-categories-${category}`"
            )
              .d-flex-1.justify-content-start.align-items-center
                input(
                  :id="category"
                  type="checkbox"
                  :value="category"
                  v-model="selectedCategoriesComputed"
                  :data-track="'filter by category ' + category"
                )
                label.template-filter-item-label.template-filter-item-label-active(:for="category") {{ $t(`templateFilter.categories.${category}`) }}
          .orange-500.cursor-pointer.font-weight-semibold.font-size-0--875(
            @click="categoryShowMore = !categoryShowMore"
          )
            i.fas.font-size-0--75.mr-2(
              :class="{ 'fa-chevron-up': categoryShowMore, 'fa-chevron-down': !categoryShowMore }"
            )
            span {{ $t(!categoryShowMore ? 'showMore' : 'showLess') }}

    om-slide-down(:title="$t('newCampaignFlow.selectGoal')" :slideActive="false")
      .template-filter-item(v-for="(goal, i) in templateGoals" :id="`template-goal-${goal}`")
        .d-flex-1.justify-content-start.align-items-center
          input(
            style="margin-top: 0.125rem"
            :id="goal"
            type="checkbox"
            :value="goal"
            @change="onChange('Goal', $event)"
            v-model="selectedGoalsComputed"
            :data-track="'filter by goal ' + goal"
          )
          label.template-filter-item-label.template-filter-item-label-active(:for="goal") {{ $t(`tactic.goal.${goal}`) }}
    om-slide-down(:title="$t('messageType')" :slideActive="false")
      .template-filter-item(
        v-for="templateType in templateTypes"
        :id="`template-type-${templateType}`"
      )
        .d-flex-1.justify-content-start.align-items-center
          input(
            :id="templateType"
            type="checkbox"
            :value="templateType"
            @change="onChange('Message type', $event)"
            v-model="selectedTypesComputed"
            :data-track="'filter ' + templateType"
          )
          label.template-filter-item-label.template-filter-item-label-active(:for="templateType") {{ $tc(`templateFilter.types.${templateType}`, 1) }}
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      templateTypes: {
        type: Array,
        default: () => [],
      },
      templateGoals: {
        type: Array,
        default: () => [],
      },
      templateCategories: {
        type: Array,
        default: () => [],
      },
      selectedTypes: {
        type: Array,
        default: () => [],
      },
      selectedCategories: {
        type: Array,
        default: () => [],
      },
      selectedGoals: {
        type: Array,
        default: () => [],
      },
      selectedLocale: {
        type: String,
      },
      opened: {
        type: Boolean,
        default: false,
      },
      newChooser: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        categoryShowMore: false,
        goalShowMore: false,
      };
    },

    computed: {
      ...mapGetters(['isMobile']),
      selectedGoalsComputed: {
        get() {
          return this.selectedGoals;
        },

        set(v) {
          this.$emit('update:selectedGoals', v);
        },
      },
      selectedCategoriesComputed: {
        get() {
          return this.selectedCategories;
        },

        set(v) {
          this.$emit('update:selectedCategories', v);
        },
      },
      selectedTypesComputed: {
        get() {
          return this.selectedTypes;
        },
        set(v) {
          this.$emit('update:selectedTypes', v);
        },
      },
    },
    methods: {
      onChange(component, event) {
        const isGoal = component === 'Goal';
        const type = isGoal ? 'goals' : 'types';
        const v = event?.target?.value;
        let transKey = `templateFilter.${type}.${v}`;
        if (isGoal) {
          transKey = `tactic.goal.${v}`;
        }

        this.$emit('track', {
          component,
          setting: 'check',
          change: event?.target?.checked ? 'on' : 'off',
          options: this.$te(transKey) ? this.$tc(transKey, 1, 'en') : '???',
        });
      },
    },
  };
</script>

<style lang="sass">
  @import '../../sass/variables/_colors.sass'
  .template-filter
    padding: 4px 40px
    @media screen and (max-width: 767px)
      padding-top: 40px
      padding-bottom: 5rem

    &-item
      display: flex
      font-size: 12px
      padding-bottom: 18px

      input
        transition: .3s

      &-label
        display: block
        width: 100%
        color: $om-gray-700
        margin-bottom: 0
        margin-left: 0.625rem
        cursor: pointer
        transition: .3s
        font-size: 16px
        @media screen and (min-width: 768px)
          font-size: 12px
          padding-right: .75rem
        &:checked
          border: 1px solid #c3cfd9

        &-active
          font-weight: 500

      &-disabled
        pointer-events: none
        input
          &:checked
            border: 1px solid #c3cfd9 !important
          background: #F6F7F9 !important
        .template-filter-item-label
          color: #AAB1C1

    .brand-separator
      background: #E6E9F0
      margin-left: -1.5625rem
      margin-right: -1.5625rem
      width: auto
      border-width: 1px
      height: 1px
      @media screen and (min-width: 768px)
        margin-top: 1.75rem
        margin-bottom: .625rem
      @media screen and (max-width: 767px)
        margin-top: .625rem
        margin-bottom: .625rem
  @media screen and (max-width: 767px)
    .template-filter
      position: fixed
      right: 0
      top: 0
      z-index: 200001
      height: 100vh
      width: 80vw
      transform: translateX(80vw)
      transition: .3s ease
      background: white
      overflow-y: scroll

      &-overlay
        position: fixed
        left: 0
        top: 0
        z-index: 200001
        width: 100vw
        height: 100vh
        background: rgba(39, 39, 39, 0.5)

      &-title-holder
        display: flex
        align-items: center
        margin-bottom: 0.875rem

      &-title
        font-size: 1.5rem
        color: #AAB1C1
        font-weight: 500
        line-height: 1.2

      &-opened
        transform: translateX(0)
</style>
