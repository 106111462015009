<template lang="pug">
.template-chooser-listing-page
  Skeleton(v-if="$apollo.loading")
  template(v-else)
    .template-chooser-listing-page__container(v-if="templates.length")
      template(v-for="(template, index) in templates")
        //- filter-banner(v-if="!isActiveFilters && index === 30" style="grid-column: span 3") // TODO uncomment
        TemplateBox(
          @observable="addObservable($event.$el)"
          :template="template"
          :key="template._id"
          heap-event="new-selector-campaign-listing"
          :themeKit="themeKit"
          @hide="hide(template)"
          @show="show(template)"
          :baseThemeKitName="baseThemeKitName"
        )
    ListEmptyState(
      v-else
      :image="require('@/assets/admin/svg/monk_magnifying.svg')"
      :title="$t('templateChooser.emptyStateBaseTitle')"
    )
</template>

<script>
  import { mapState } from 'vuex';
  import GET_CHOOSER_FILTERED_TEMPLATES from '@/graphql/GetChooserFilteredTemplates.gql';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import childRouteMixin from '../mixins/childRoute';
  import templateBoxParent from '../mixins/templateBoxParent';
  import filterHelperMixin from '../mixins/filterHelper';

  export default {
    components: {
      ListEmptyState: () => import('@/components/TemplateChooser/components/ListEmptyState.vue'),
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
      TemplateBox: () => import('@/components/TemplateChooser/components/TemplateBox.vue'),
      FilterBanner: () => import('@/components/TemplateChooser/components/FilterBanner.vue'),
    },
    mixins: [childRouteMixin, observableCollectionMixin, templateBoxParent, filterHelperMixin],
    data: () => ({ templates: [], searchResults: [] }),
    computed: {
      ...mapState(['accountType']),
      isThemesRoute() {
        return this.$route.name.includes('theme');
      },
      isCustoms() {
        return this.$route.name === 'custom-templates';
      },
      isSeasonal() {
        return this.$route.name.includes('seasonal');
      },
      filterKey() {
        if (this.isThemesRoute) return 'themeKit';
        if (this.isSeasonal || this.isCustoms) return 'category';
        return null;
      },
      filterValue() {
        if (this.isThemesRoute) {
          const theme = this.getTheme();
          return theme?.sourceTheme ?? theme?._id;
        }
        return this.isCustoms ? [] : this.$route.params.slug;
      },
      mergedThemes() {
        return [...Object.values(this.themes)].flat();
      },
      themeKit() {
        if (!this.isThemesRoute) return null;
        const theme = this.getTheme();
        const themeKit = theme?.themeKit || null;
        if (theme?.sourceTheme && themeKit) {
          themeKit.source = theme?.sourceTheme;
        }
        return themeKit;
      },
      baseThemeKitName() {
        if (this.isSeasonal) {
          const key = `templateFilter.categories.${this.$route.params.slug}`;

          return this.$te(key) ? this.$t(key) : null;
        }

        const theme = this.getTheme();
        if (theme?.sourceTheme) {
          const baseTheme = this.mergedThemes.find(({ _id }) => _id === theme.sourceTheme);
          return baseTheme?.name;
        }
        return theme?.name;
      },
    },
    apollo: {
      templates: {
        query: GET_CHOOSER_FILTERED_TEMPLATES,
        variables() {
          const filter = { ...this.filter, search: this.search };
          if (this.filterKey) {
            filter[this.filterKey] = this.filterValue;
          }
          return { filter, type: this.accountType };
        },
        update({ templates }) {
          this.searchResults = templates?.searchResults ?? [];
          if (this.isThemesRoute || this.isCustoms) {
            const possibleTemplates = Object.values(templates.byTheme).flat();
            return this.getTemplates(possibleTemplates, this.searchResults);
          }
          const possibleTemplates = templates.byCategory[this.$route.params.slug];
          return this.getTemplates(possibleTemplates, this.searchResults);
        },
        result() {
          this.$emit('setLoading', false);
        },
      },
    },
    methods: {
      getIdFromSlug() {
        return this.$route.params?.slug?.split('-')?.splice(-1)?.[0] ?? 'Custom';
      },
      getTheme() {
        const id = this.getIdFromSlug();

        return this.mergedThemes.find(({ _id }) => _id === id);
      },
      hide(template) {
        template.hidden = true;
      },
      show(template) {
        template.hidden = false;
      },
      getPossibleTemplates() {
        return this.templates;
      },
      getTemplates(items, searchResults) {
        if (this.search) {
          return searchResults
            ?.map?.(({ _id: relevantId }) => {
              return items?.find?.(({ _id }) => relevantId === _id);
            })
            .filter((v) => !!v);
        }
        return items ?? [];
      },
    },
  };
</script>

<style lang="sass">
  .template-chooser-listing-page
    &__container
      display: grid
      grid-template-columns: auto auto auto
      column-gap: 1.5rem
      row-gap: 2.5rem

    .template-chooser-page-loading-skeleton
      .loading-row
        margin-bottom: 3.5rem !important
</style>
