import { render, staticRenderFns } from "./OnClickAction.vue?vue&type=template&id=ecc04a60&scoped=true&lang=pug&"
import script from "./OnClickAction.vue?vue&type=script&lang=js&"
export * from "./OnClickAction.vue?vue&type=script&lang=js&"
import style0 from "./OnClickAction.vue?vue&type=style&index=0&id=ecc04a60&lang=sass&scoped=true&"
import style1 from "./OnClickAction.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecc04a60",
  null
  
)

export default component.exports